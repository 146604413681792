@import "common.css";
@import "DatePicker.css";

@import url("https://fonts.googleapis.com/css2?family=Croissant+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oleo+Script:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inria+Serif:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Serif+4:ital,opsz,wght@0,8..60,200..900;1,8..60,200..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anek+Malayalam:wght@100..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Racing+Sans+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Caudex:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Amaranth:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bigshot+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Istok+Web:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Candal&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Marko+One&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Yrsa:ital,wght@0,300..700;1,300..700&display=swap");
@font-face {
  font-family: Sansita;
  src: url("../fonts/SansitaOne.ttf");
}

@font-face {
  font-family: Cambria;
  src: url("../fonts/Cambria-Font-For-Windows.ttf");
}

* {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

body {
  font-family: "Lato", sans-serif;
  color: #676767;
  font-size: 16px;
  font-weight: 500;
}

a {
  text-decoration: none;
  color: #676767;
}

button {
  background-color: transparent;
  border: none;
  padding: 0;
}

/*.........................................................*/

.login-screen {
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(../images/bg_img.jpg);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 15px;
  background-attachment: fixed;
}

.frame-parent {
  max-width: 880px;
  margin: auto;
  border-radius: 30px;
  background: #fff;
  box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.13);
  width: 100%;
}

.group-login-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../images/bg-login.jpg);
  border-radius: 30px 0 0 30px;
  padding: 60px 38px 100px 38px;
  position: relative;
}

.login-using-social {
  font-weight: 500;
  font-size: 20px;
  max-width: 300px;
  margin: 0 auto;
  letter-spacing: 0.2px;
  line-height: 26px;
  margin-top: 65px;
}
.gm-style-iw-ch {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  flex-shrink: 1;
  padding-top: 9px;
  overflow: hidden;
}
.btn-login {
  margin-top: 20px;
  border-radius: 5px;
  border: 0.848px solid #000;
  background: #fff;
  text-align: center;
  padding: 13px 10px;
  color: rgba(0, 0, 0, 0.8);
  display: block;
  width: 100%;
  font-size: 12px;
  font-weight: 600;
}

.btn-login svg {
  margin-right: 10px;
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: middle;
}

.padding-inner {
  padding: 40px 30px;
}

.nav-item {
  text-align: center;
  width: 50%;
}

.nav-tabs .nav-link {
  border: none;
  padding: 14px;
  letter-spacing: 0.2px;
  text-align: center;
  font-size: 20px;
  color: #6b6b6b;
  font-weight: 500;
  border-bottom: 3px solid #b9b9b9;
  width: 100%;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-bottom: 3px solid #488690;
  color: #488690;
  font-weight: 700;
}

#myTab {
  margin-bottom: 30px;
}

:focus-visible {
  outline: none;
}

h3 {
  color: #000;
  font-size: 22px;
  font-weight: 600;
}

.dateTimePicker .group-input input {
  padding: 14px 15px 14px 15px !important;
}

.dateTimePicker .MuiStack-root {
  padding-top: 0 !important;
}

.group-input {
  padding: 2px;
  border-radius: 5px;
  position: relative;
  margin-top: 20px;
  background: linear-gradient(180deg, #0dbcec, #ffc056);
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
  width: 100%;
}

.time-picker {
  background: linear-gradient(180deg, #0dbcec, #ffc056);
  padding: 2px;
  border-radius: 5px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
  width: 100%;
}

.group-input svg {
  position: absolute;
  left: 16px;
  top: 13px;
}

.group-input input {
  background-color: #ffffff;
  padding: 14px 15px 14px 50px;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  border: none;
  border-radius: 3px;
  width: 100%;
}

.group-input input::placeholder {
  color: #949494;
}

.hide-show-icon {
  position: absolute;
  right: 15px;
  top: 10px;
}

.gred-checkbox {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 30px;
  font-size: 14px;
  color: #262626;
  font-weight: 500;
  line-height: 20px;
}

.gred-checkbox input[type="checkbox"] {
  opacity: 0;
}

.gred-checkbox input:checked ~ .checkmark:before {
  background: linear-gradient(180deg, #0dbcec, #ffc056);
}

.clearfix {
  clear: both;
}

.gred-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.gred-checkbox .checkmark::after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  aspect-ratio: 1;
  width: 20px;
  padding: 2px;
  border-radius: 4px;
  background: linear-gradient(180deg, #0dbcec, #ffc056);
}

.checkmark::after {
  content: "";
  position: absolute;
  display: none;
}

.checkmark:before {
  content: "";
  position: absolute;
  background-color: #ffffff;
  height: 16px;
  width: 16px;
  top: 2px;
  left: 2px;
  border-radius: 2px;
}

.login-link {
  font-weight: 500;
  color: #262626;
  font-size: 14px;
  text-decoration: none;
}

.login-link:hover {
  color: #488690;
}

/*.btn_gred{
	background: linear-gradient(180deg, rgba(92, 203, 234, 1), rgba(239, 206, 150, 1));
    position: relative;
    padding: 2px;
    clear: both;
    display: block;
    width: 100%;
    max-width: 345px;
    border-radius: 5px;
    margin: 0 auto;
    margin-top: 60px;
}
.btn_gred:before{
    position: absolute;
    content: "";
    width: 44px;
    height: 44px;
    rotate: 45deg;
    top: 7px;
    left: -18px;
    background: linear-gradient(85deg, rgba(92, 203, 234, 1), rgba(239, 206, 150, 1));
    border-radius: 5px;
}
.btn_gred:after{
    position: absolute;
    content: "";
     width: 44px;
    height: 44px;
    rotate: 45deg;
    top: 7px;
    right: -18px;
    background: linear-gradient(85deg, rgba(92, 203, 234, 1), rgba(239, 206, 150, 1));
    border-radius: 5px;
}*/
/*.btn_admina{
	text-align: center;
    border: none;
    padding: 18px 15px;
    position: relative;
    font-size: 20px;
    font-weight: 600;
    width: 100%;
    line-height: 20px;
    background: transparent;
    z-index: 1;
    border-radius: 4px;
    background: linear-gradient(90deg, rgba(92, 203, 234, 1), rgba(239, 206, 150, 1));
}*/
/*.btn_admina:before{
    position: absolute;
    content: "";
   	width: 38px;
    height: 38px;
    rotate: 45deg;
    top: 8px;
    left: -16px;
    background: rgba(92, 203, 234, 1);
    border-radius: 5px;
}
.btn_admina:after{
    position: absolute;
    content: "";
    width: 38px;
    height: 38px;
    rotate: 45deg;
    top: 8px;
    right: -16px;
    background: rgba(239, 206, 150, 1);
    border-radius: 5px;
}
*/
p {
  font-size: 14px;
}

.btn_admina {
  text-align: center;
  border: none;
  padding: 22px 15px;
  position: relative;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  max-width: 390px;
  line-height: 20px;
  background: transparent;
  z-index: 1;
  background-image: url(../images/button-bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  text-decoration: none;
  color: #000;
}

.checkColorFull {
  background-image: url(../images/eventCheck.png);
}

.btn_admina:hover {
  color: #000;
}

.btn_White {
  text-align: center;
  border: none;
  padding: 22px 15px;
  position: relative;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  max-width: 390px;
  line-height: 23px;
  background: transparent;
  z-index: 1;
  background-image: url(../images/cenacleBtn.svg);
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  text-decoration: none;
  color: #000;
}

.event_white_btn {
  text-align: center;
  border: none;
  padding: 22px 15px;
  position: relative;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  max-width: 390px;
  line-height: 23px;
  background: transparent;
  z-index: 1;
  background-image: url(../images/event-white-btn.png) !important;
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  text-decoration: none;
  color: #000;
}

.mini_white_btn {
  text-align: center;
  border: none;
  padding: 22px 15px;
  position: relative;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  max-width: 390px;
  line-height: 23px;
  background: transparent;
  z-index: 1;
  background-image: url(../images/miniWhitebtn.png);
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  text-decoration: none;
  color: #000;
}

.checkBtnwhite {
  background-image: url(../images/eventCheckWhite.png);
}

.btn_White:hover {
  color: #000;
}

.btn_gred {
  text-align: center;
  margin-top: 60px;
}

.group-input input[type="password"] {
  padding-right: 44px;
}

.min-screen {
  max-width: 550px !important;
}

.login_margin {
  margin-top: 45px;
}

.page-title {
  font-size: 24px;
  margin-bottom: 15px !important;
}

.min-screen {
  min-height: 720px;
}

.padding-inner {
  padding: 40px;
  position: relative;
}

.back-button {
  border-radius: 50px;
  background: #eee;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  display: inline-block;
  position: absolute;
  left: 20px;
  top: 20px;
}

.send_link {
  font-weight: 600;
  font-size: 16px;
  text-decoration-line: underline;
  color: #000000;
}

.send_link:hover {
  color: #488690;
}

.otp-field {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.otp-field .group-input {
  margin: 0 15px;
  background: none;
  padding: 0;
  box-shadow: none;
}

.otp-field .group-input input {
  padding: 6px;
  width: 78px;
  height: 64px;
  background-color: #ffffff;
  font-size: 24px;
  color: #000000;
  font-weight: 600;
  text-align: center;
  background-image: url(../images/polygon.svg);
  background-repeat: no-repeat;
  background-position: center;
  -webkit-appearance: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
  width: calc(100% - 100px);
}

.otp-field {
  margin: 40px 0;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #626262;
  font-size: 18px;
  font-weight: 500;
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  border: 1px solid #94b0b8;
  border-radius: 100%;
  background: #fff;
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 16px;
  height: 16px;
  background: #6bcbe1;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.12);
}

[type="radio"]:checked + label:before {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.12);
  border-color: #6bcbe1;
}

[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.input-label {
  font-weight: 600;
  font-size: 18px;
  color: #000;
  margin-bottom: 10px;
}

.input-desc {
  color: #949494;
  font-weight: 500;
}

.custom-upload {
  min-width: 107px;
  width: 107px;
  height: 100px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  margin-right: 20px;
  background-image: url(../images/shape.png);
}

.custom-upload input {
  position: absolute;
  z-index: 4;
  opacity: 0;
  height: 100%;
  width: 100%;
}

.custom-upload-2 {
  min-width: 72px;
  width: 72px;
  height: 67px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  background-image: url(../images/shap2.png);
}

.custom-upload-2 input {
  position: absolute;
  opacity: 0;
  height: 100%;
  width: 100%;
}

.p-20 {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.dashed-border {
  border-radius: 10px;
  border: 1.5px dashed rgba(0, 0, 0, 0.6);
}

textarea.dashed-border {
  padding: 15px;
  height: 70px;
  width: 100%;
}

.group-input select {
  width: 100%;
  padding: 12px 15px;
  background-color: #ffffff;
  padding: 15px 20px;
  font-size: 20px;
  font-weight: 500;
  color: #000000;
  border: none;
  border-radius: 3px;
}

.selected-item {
  font-size: 14px;
  color: #767676;
  margin-top: 10px;
  font-weight: 600;
}

.p-15 input {
  padding: 15px 20px 15px 20px;
}

.hexagone {
  width: 70px;
  height: 62px;
  display: inline-block;
  position: relative;
  margin-right: 15px;
  margin-bottom: 15px;
  vertical-align: middle;
}

.hexagone img {
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hexagone p {
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.remove {
  position: absolute;
  right: 5px;
  top: -5px;
  width: 20px;
  height: 20px;
  border-radius: 100px;
  background-color: #ffd8d8;
  z-index: 1;
  line-height: 14px;
  cursor: pointer;
  background-position: center;
}

.main-page {
  background-image: url(../images/bg_img2.jpg);
  min-height: calc(100vh - 101px);
  background-size: cover;
  background-position: bottom right;
  padding-top: 100px;
  background-attachment: fixed;
}

.container {
  max-width: 1440px;
}

.pr-img {
  min-width: 66px;
}

.profile h4 {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 4px;
}

.profile p {
  color: #3e555b;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

header {
  background-color: #ffffff;
  background: #fff;
  padding: 4px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
}

header:after {
  position: absolute;
  content: "";
  height: 2px;
  width: 100%;
  bottom: 0;
  background: linear-gradient(90deg, #0dbcec, #ffc056);
}

.t-red {
  color: #d91c3f !important;
}

.action-btn button {
  background: transparent;
  border: none;
  padding: 0;
}

.action-btn .dropdown-menu {
  right: 0 !important;
  left: auto !important;
  transform: none !important;
  top: 100% !important;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
  min-width: 200px;
  padding: 15px;
}

.action-btn .dropdown-menu .dropdown-item {
  padding: 0 0 10px 0;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  cursor: pointer;
  border-bottom: 1px solid #dddddd;
}

.action-btn .dropdown-menu .dropdown-item img {
  margin-right: 10px;
}

.action-btn .dropdown-menu li:last-child .dropdown-item {
  border-bottom: none;
  padding: 0 0 0 0;
  margin-bottom: 0;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background: transparent;
}

.main-inner {
  padding: 60px 0;
}

h1 {
  font-size: 35px;
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
  letter-spacing: 0.35px;
  background: linear-gradient(to right, #0dbcec 0%, #ffc056 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.para {
  font-size: 22px;
  letter-spacing: 0.3px;
  color: #676767;
}

.border-gred {
  border-radius: 10px;
  padding: 2px;
  background: linear-gradient(180deg, #0dbcec, #ffc056);
  box-shadow: 0px 0px 10px 0px rgba(172, 209, 217, 0.75);
  margin-bottom: 20px;
}

.inner-gred {
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
}

.inner-gred-yellow {
  padding: 20px;
  background: rgba(255, 165, 0, 0.1);
  border-radius: 8px;
}

.p_image_shape {
  width: 99.554px;
  cursor: pointer;
  height: 87px;
  overflow: hidden;
  background-image: url(../images/shape-3.png);
  text-align: center;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  z-index: 5;
  padding: 2px;
  min-width: 99.554px;
}

.t_image_shape {
  width: 99.554px;
  height: 87px;
  overflow: hidden;
  background-image: url(../images/shape-6.png);
  text-align: center;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  z-index: 5;
  padding: 2px;
  min-width: 99.554px;
}

.review_shape {
  width: 60px;
  height: 50px;
  overflow: hidden;
  background-image: url(../images/shape5.png);
  text-align: center;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  z-index: 5;
  /*padding: 2px;*/
  min-width: 60px;
}

.review_shape img {
  width: 60px;
  height: 50px;
  object-fit: cover;
  object-position: center;
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
}

.p_image_shape img {
  width: 96px;
  height: 83px;
  object-fit: cover;
  object-position: center;
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
}

.t_image_shape p {
  width: 96px;
  height: 83px;
  object-fit: cover;
  object-position: center;
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
}

.p_image_shape img.blue_check {
  position: absolute;
  right: 5px;
  top: 7px;
  clip-path: none;
  height: 20px;
  width: 20px;
  z-index: 2;
}

.border-gred h4 {
  font-weight: 600;
  font-size: 18px;
  color: #000000;
}

.border-gred p {
  color: #3e555b;
  font-size: 16px;
  font-weight: 700;
}

.inner-gred ul {
  padding: 0;
  margin: 0;
}

.inner-gred ul li {
  list-style: none;
  position: relative;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  line-height: 24px;
}

.inner-gred ul li:last-child {
  margin-bottom: 0;
}

.inner-gred ul li svg {
  /*position: absolute;*/
  left: 0;
  top: 0;
  margin-right: 10px;
}

.dec_title {
  color: #1a1a1a;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;
}

p.about-desc {
  color: #767676;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}

.edit-number {
  text-align: right;
  width: 91px;
  padding: 6px 10px;
  border-radius: 7px;
  border: none;
  color: #69470c;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}

.edit-number:focus {
  border: none;
}

.polygon-imgae .p_image_shape img {
  width: 213px;
  height: 182px;
  margin: 0;
}

.polygon-imgae {
  cursor: pointer;
}

.t-polygon-imgae .t_image_shape p {
  width: 164px;
  height: 188px;
  margin: 0;
}

.polygon-imgae .p_image_shape {
  width: 216px;
  height: 186px;
  background-image: url(../images/shape-4.png);
  background-size: cover;
}

.t-polygon-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.t-polygon-imgae .t_image_shape {
  width: 164px;
  height: 188px;
  background-image: url(../images/shape-6.png);
  background-size: contain;
}

.round-image {
  display: inline-block;
  background-image: url(../images/bg-round.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transform: scale(0.9);
}

.round-image6 {
  display: inline-block;
  background-image: url(../images/bg-round.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transform: scale(0.9);
}

@media (max-width: 500px) {
  .six-image {
    margin-top: 0px !important;
    transform: scale(0.5) !important;
  }
}

@media (max-width: 575px) {
  .save-page-btn {
    width: 100% !important;
  }

  .round-image6 {
    margin-top: -115px !important;
    transform: scale(0.6) !important;
    margin-bottom: -80px !important;
  }

  .massageBtn3 {
    height: 48px !important;
    max-width: 307px !important;
  }
  .six-image {
    transform: scale(0.5) !important;
    margin-top: -60px !important;
    margin-bottom: -34px !important;
  }
}

section {
  overflow-x: hidden;
}

.modal-content {
  padding: 20px;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.13);
}

/* .modal-dialog {
    max-width: 530px;
} */

.close-modal {
  float: right;
  display: inline-block;
  width: auto;
  background: transparent;
  border: none;
  margin-left: auto;
  padding: 0;
}

.upadte_profile {
  display: inline-block;
  position: relative;
  width: 104px;
  height: 104px;
  background: linear-gradient(180deg, #0dbcec, #ffc056);
  border-radius: 100%;
  padding: 2px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25);
}

.upadte_profile .pr-second {
  height: 100px;
  width: 100px;
  border-radius: 100%;
}

.edit-btn {
  background: #eaeaea;
  width: 40px;
  height: 40px;
  border: 2px solid #ffffff;
  border-radius: 100px;
  padding: 4px;
  position: absolute;
  bottom: 8px;
  right: -12px;
}

.edit-btn input {
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  position: relative;
}

.edit-btn .icon {
  position: absolute;
  left: 6px;
}

.modal-backdrop.show {
  opacity: 0.7;
}

.user-profile {
  margin: 30px 0;
}

.user-profile .custom-upload {
  margin: 0 auto;
  width: 219px;
  height: 202px;
  background-image: url(../images/shpe-big.png);
}

.anim-image img {
  display: inline-block;
  margin: 0 auto;
  animation: rotation 2s infinite linear;
}

.Congratulations_msg {
  padding: 10px;
  border-radius: 10px;
  background: #ffffff;
  max-width: 415px;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.12);
}

.auto-modla {
  padding: 10px;
  border-radius: 10px;
  background: #ffffff;
  max-width: 100% !important;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.12);
}

.payment-details-modal-mx {
  padding: 10px;
  border-radius: 10px;
  background: #ffffff;
  max-width: 100% !important;
  width: auto !important;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.12);
}

.Congratulations_msg .group-parent {
  padding: 20px 0;
  border-radius: 10px;
  background: linear-gradient(180deg, #b9f0ff 0%, #ffeac6 100%);
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.group-input input.select-servcesss {
  /*background-image: url(../images/Iconsdown.svg);*/
  position: relative;
  background-repeat: no-repeat;
  background-position: 98% center;
  padding: 15px 20px;
  cursor: pointer;
}

.list-open {
  border-radius: 4px 4px 0px 0px;

  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12);
  padding: 0 15px;
  overflow: hidden;
  border-radius: 0 0 10px 10px;
  width: 100%;
  height: 0;
  position: absolute;
  z-index: 2;
  transition: all 0.5s cubic-bezier(0.82, 0.085, 0.395, 0.895);
}

.list-open.open {
  height: auto;
  max-height: 300px;
  overflow-y: auto;
  border-bottom: 2px solid #fff;
}

.list-open h4 {
  color: #69470c;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}

.list-open label {
  font-size: 14px;
  color: #000;
  font-weight: 700;
}

.list-open .group-input-check {
  clear: both;
  overflow: hidden;
}

.profile_uploaded {
  position: absolute;
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.profile_uploaded-after {
  z-index: 1;
  position: relative;
  background: rgb(255 255 255 / 66%);
  border-radius: 100px;
}

.f-18 {
  font-size: 18px;
}

.max-width-300 {
  max-width: 300px;
  margin: 0 auto;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  animation: slideDown 0.6s ease-in-out;
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.group-input input {
  line-height: 17px;
}

[data-bs-target="#service_Add"] {
  cursor: pointer;
}

.group-input input.select-servcesss {
  line-height: 16px;
}

.group-input input[type="date"] {
  width: 100%;
  min-width: calc(100% - 16px);
  max-width: 100%;
  text-align: left;
  margin-left: 0;
  margin-right: auto;
  display: block;
  line-height: 17px;
}

.selected_services span {
  display: inline-block;
  vertical-align: middle;
  border-radius: 5px;
  background: linear-gradient(
    90deg,
    rgba(92, 203, 234, 0.5) 0%,
    rgba(239, 206, 150, 0.5) 100%
  );
  padding: 5px 10px;
  color: #4b4b4b;
  font-size: 15px;
  font-weight: 600;
  margin: 5px 10px 0 0;
}

.selected_languages span {
  display: inline-block;
  vertical-align: middle;
  border-radius: 5px;
  background: #ececec;
  padding: 5px 10px;
  color: #4b4b4b;
  font-size: 12px;
  font-weight: 600;
  margin: 5px 10px 0 0;
}

.service_new_list li {
  padding: 0 !important;
  list-style: disc !important;
  margin-left: 15px;
  margin-bottom: 10px !important;
}

.service_new_list li h5 {
  font-size: 15px;
  color: #1a1a1a;
  font-weight: 700;
}

.service_new_list li div span {
  color: #767676;
  font-size: 14px;
  margin: 0 25px 10px 0;
}

.add_service_modal .modal-dialog {
  max-width: 820px;
}

.detail_location {
  border-radius: 10px;
  border: 1px solid #d0dee2;
  padding: 15px;
  background: rgba(255, 255, 255, 0.5);
  margin-top: 25px;
  box-shadow: 0px 0px 10px 0px rgba(172, 209, 217, 0.75);
}

.detail_location h4 {
  font-size: 18px;
  color: #323232;
  margin-bottom: 15px;
  font-weight: 600;
}

.tablist {
  border-radius: 10px;
  background: linear-gradient(
    180deg,
    rgba(92, 203, 234, 0.3) 0%,
    rgba(239, 206, 150, 0.3) 100%
  );
  padding: 5px 10px;
  display: flex;
  align-items: center;
}

.tablist span {
  height: 40px;
  font-size: 14px;
  font-weight: 600;
  color: #585858;
  line-height: 40px;
  border-radius: 5px;
  width: 33.33%;
  cursor: pointer;
  text-align: center;
  background-color: transparent;
}

.tablist span:hover,
.tablist span.t_active {
  background-color: #ffffff;
  color: #000000;
}

.location_title {
  padding-top: 15px;
  margin-top: 15px;
  border-top: 1px solid #d3d3d3;
}

.group-input textarea {
  background-color: #ffffff;
  padding: 17px 15px;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  border: none;
  border-radius: 3px;
  width: 100%;
  margin-bottom: -5px;
}

/******** responsive ********/
@media (max-width: 1320px) {
  .round-image {
    transform: scale(0.7);
    display: block;
  }
}

@media (max-width: 1200px) {
  .frame-parent {
    max-width: 800px;
  }

  .save-page-content {
    width: 100%;
    max-width: 65%;
  }
}

#scrollableDiv .infinity-list {
  margin-top: 0px;
  overflow-x: hidden !important;
}

#scrollableDiv {
  background-color: white;
}

@media (max-width: 991px) {
  .group-login-bg {
    padding-left: 20px;
    padding-right: 20px;
  }

  #scrollableDiv .infinity-list {
    /*margin-top: 400px;*/
  }

  #scrollableDiv {
    background-color: transparent;
    top: calc(100vh - 300px) !important;
    border-radius: 20px 20px 0 0;
    padding-top: 20px !important;
  }

  #scrollableDiv::after {
    position: absolute;
    content: "";
    width: 70px;
    height: 6px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #d8d8d8;
    top: 10px;
    border-radius: 3px;
  }

  .homepadding {
    padding: 0 !important;
    padding-left: 5px !important;
  }

  .main-inner {
    padding: 40px 0;
  }

  .main-page {
    background-size: inherit;
  }

  .para {
    font-size: 20px;
  }

  .round-image {
    transform: scale(0.9);
    margin-top: 20px;
  }
}

@media (max-width: 767px) and (min-width: 576px) {
  .group-login-bg {
    padding: 40px 15px 80px 15px;
  }

  .padding-inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-height: 767px) {
  .min-screen {
    min-height: 600px;
  }
}

@media (max-width: 767px) {
  .card-width {
    max-width: 80% !important;
  }

  .login-using-social {
    font-size: 20px;
  }

  .btn_admina {
    font-size: 18px;
    padding: 18px 15px;
    background-size: 100%;
    background-position: center;
  }

  .btn_White {
    font-size: 18px;
    padding: 18px 15px;
    background-size: 100%;
    background-position: center;
  }

  .para {
    font-size: 18px;
  }

  .inner-gred {
    padding: 15px 10px;
  }

  .p_image_shape {
    width: 78.6px;
    height: 62.5px;
    min-width: 78.6px;
    background-size: contain;
  }

  .t_image_shape {
    width: 78.6px;
    height: 62.5px;
    min-width: 78.6px;
    background-size: contain;
  }

  .p_image_shape2 {
    width: 30px;
    height: 30px;
    min-width: 78.6px;
    background-size: contain;
  }

  .p_image_shape2 img {
    width: 30px;
    height: 30px;
    margin-top: -1px;
    margin-right: 0px;
  }

  .p_image_shape img {
    width: 69px;
    height: 60.6px;
    margin-top: -1px;
    margin-right: 0px;
  }

  .usericoninevent {
    width: 77px !important;
  }

  .t_image_shape p {
    width: 69px;
    height: 60.6px;
    margin-top: -1px;
    margin-right: 0px;
  }

  .border-gred h4 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .border-gred p {
    font-size: 16px;
  }

  .inner-gred ul li {
    font-size: 14px;
    margin-bottom: 11px;
    /*padding-left: 30px;*/
  }

  .border-gred p {
    font-size: 14px;
  }

  .round-image {
    transform: scale(0.8);
    margin-top: -20px;
    margin-bottom: -30px;
  }

  .polygon-imgae .p_image_shape {
    background-size: initial;
  }

  .t-polygon-imgae .t_image_shape {
    /*background-size: initial;*/
  }
}

@media (max-width: 1024px) {
  .round-image-7 {
    transform: scale(0.7);
  }

  .service-layout-scale {
    transform: scale(0.7);
  }

  .service-layout-scale1 {
    transform: scale(1) !important;
  }

  .bnt-lignHight {
    line-height: 19px !important;
  }

  .card-width {
    max-width: 80% !important;
  }
}

@media (max-width: 1200px) {
  .bnt-lignHight {
    line-height: 19px !important;
  }

  .checkColorFull {
    line-height: 44px !important;
    transform: scale(0.8) !important;
  }

  .checkBtnwhite {
    line-height: 44px !important;
    transform: scale(0.8) !important;
  }
}

@media (max-width: 1260px) {
  .card-width {
    max-width: 70% !important;
  }
}

@media (max-width: 375px) {
  .delete-button {
    font-size: 15px;
  }

  .location_on_modify_hire {
    width: 50%;
  }
}

@media (max-width: 575px) {
  .card-width {
    max-width: 100% !important;
  }

  .group-login-bg {
    border-radius: 20px 20px 0 0;
    padding: 0px 15px 30px 15px;
  }

  .btn-login {
    padding: 10px;
  }

  .logo {
    width: 152px;
  }

  .login-using-social {
    font-size: 18px;
    margin-top: -10px;
    line-height: 26px;
  }

  .btn-login {
    margin-top: 10px;
  }

  .padding-inner {
    padding: 20px 15px 30px 15px;
  }

  .nav-tabs .nav-link {
    padding: 10px;
    font-size: 18px;
  }

  #myTab {
    margin-bottom: 20px;
  }

  .group-input input {
    padding: 11px 15px 11px 40px;
    font-size: 16px;
  }

  .group-input svg {
    top: 10px;
    left: 10px;
  }

  .gred-checkbox {
    font-size: 16px;
  }

  .btn_gred {
    margin-top: 20px;
  }

  .btn_admina {
    padding: 17px 15px;
  }

  .btn_White {
    padding: 17px 15px;
  }

  .bnt-lignHight {
    line-height: 19px !important;
  }

  .checkColorFull {
    line-height: 39px !important;
    transform: scale(0.8) !important;
  }

  .checkBtnwhite {
    line-height: 39px !important;
    transform: scale(0.8) !important;
  }

  .login-screen {
    padding: 30px 15px;
  }

  .back-button {
    width: 44px;
    height: 44px;
    line-height: 44px;
  }

  .login_margin {
    margin-top: 30px;
  }

  .otp-field {
    margin: 30px 0;
    justify-content: center;
  }

  .otp-field .group-input {
    margin: 0 8px;
  }

  .otp-field .group-input input {
    width: 50px;
    height: 40px;
    font-size: 20px;
    background-size: contain;
  }

  .hide-show-icon {
    top: 8px;
    right: 10px;
  }

  .hide-show-icon img {
    width: 20px;
  }

  .pr-img {
    min-width: 40px !important;
    height: 40px !important;
    width: 40px !important;
  }

  .profile h4 {
    line-height: 20px;
    font-size: 14px;
  }

  .profile p {
    font-size: 12px;
    line-height: 14px;
  }

  h1 {
    font-size: 30px;
  }

  .round-image {
    margin-top: 50px;
    transform: scale(1.1);
    margin-bottom: 50px;
  }

  .round-image4 {
    margin-top: 50px;
    transform: scale(0.8) !important;
    margin-bottom: 50px;
  }

  .service-layout-scale {
    transform: scale(0.6);
    /*height: 462px !important;*/
    /*margin-top: -101px !important;*/
  }

  .service-layout-scale-4 {
    height: 462px !important;
    margin-top: -101px !important;
  }

  .service-layout-scale-6 {
    height: 462px !important;
    margin-top: -101px !important;
  }

  .service-layout-scale1 {
    transform: scale(1) !important;
  }

  .round-image-7 {
    transform: scale(0.5);
    height: 380px !important;
    margin-top: -80px !important;
  }

  .group-input select {
    padding: 11px 15px;
    font-size: 18px;
  }

  .p-15 input {
    padding: 11px 15px 11px 15px !important;
  }

  .group-input input.select-servcesss {
    padding: 11px 15px;
  }

  .list-open {
    padding: 0 10px;
  }

  .group-input input[type="date"] {
    height: 39px;
    display: block;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    min-height: 1.5em;
  }

  .selected_services span {
    margin: 5px 5px 0 0;
    padding: 3px 3px;
    font-size: 10px;
  }
}

@media (max-width: 319px) {
  .btn-login {
    font-size: 14px;
  }

  .btn-login svg {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
}

@media (min-width: 767px) {
  .pading_user {
    padding-top: 28px;
    padding-bottom: 28px;
  }

  .note {
    font-weight: 600;
    font-size: 30px;
    color: #585858;
    letter-spacing: 0.3px;
  }
}

@media (max-width: 1340px) and (min-width: 767px) {
  .checkmark:before {
    width: 15px;
    top: 2px;
    left: 3px;
  }

  .gred-checkbox .checkmark::after {
    top: 3px;
  }
}

.react-tel-input .form-control {
  width: 100% !important;
  background-color: #ffffff !important;
  padding: 17px 15px 17px 60px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #000000 !important;
  border: none !important;
  border-radius: 3px !important;
}

.react-tel-input .selected-flag {
  height: 100% !important;
  padding: 0 0 0 15px !important;
  border: none !important;
}

.react-tel-input .selected-flag:focus:before,
.react-tel-input .selected-flag.open:before,
.react-tel-input .selected-flag:before {
  border: none !important;
  box-shadow: none !important;
}

@media (max-width: 575px) {
  .react-tel-input .form-control {
    width: 100% !important;
    padding: 10px 15px 10px 55px !important;
  }

  footer p {
    font-size: 8px;
  }
}

.autocomplete-dropdown-container > div {
  padding: 5px 20px;
}

.preloader {
  position: fixed;
  z-index: 101;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
}

.hexagon {
  fill: none;
  stroke-width: 0.04;
  transition: fill 0.5s ease;
}

/*.background {
  stroke: #EFCE96;
}*/

.trace {
  stroke: #5ccbea;
  stroke-dasharray: 1 2;
  stroke-dashoffset: 2;
  stroke-linecap: round;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  100% {
    stroke-dashoffset: -1;
  }
}

.loader {
  width: 16px;
  height: 16px;
  position: relative;
  left: calc(50% - 8px) !important;
  border-radius: 50%;
  color: #ffffff;
  animation: fill 1s ease-in infinite alternate;
}

.blackloader {
  width: 16px;
  height: 19px;
  position: relative;
  left: calc(50% - 8px) !important;
  border-radius: 50%;
  color: #b6b6b6 !important;
  animation: fill 1s ease-in infinite alternate;
}

.loader::before,
.loader::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  left: 30px;
  top: 0;
  animation: fill 0.9s ease-in infinite alternate;
}

.loader::after {
  left: auto;
  right: 30px;
  animation-duration: 1.1s;
}

@keyframes fill {
  0% {
    box-shadow: 0 0 0 2px inset;
  }
  100% {
    box-shadow: 0 0 0 10px inset;
  }
}

.largemodal .modal-dialog {
  max-width: 1020px;
}

.timeicom {
  background-image: url(../images/Time.svg);
  background-repeat: no-repeat;
  background-position: 98% center;
}

@media (max-width: 575px) {
  .remove.new-remove {
    top: 13px !important;
    right: 10px !important;
    width: 18px;
    height: 18px;
  }

  .remove.new-remove svg {
    position: initial;
    width: 18px;
    height: 18px;
  }
}

ul,
ol {
  list-style: none;
}

.header-menu li {
  font-size: 16px;
  font-weight: 500;
  margin-left: 30px;
  margin-right: 30px;
  cursor: pointer;
}

.header-menu li.active {
  color: #ffa500;
}

footer {
  position: relative;
}

footer:after {
  position: absolute;
  content: "";
  height: 2px;
  width: 100%;
  top: 0;
  background: linear-gradient(90deg, #0dbcec, #ffc056);
}

/* .delete-modal {
    width: 450px;
} */

.discount-modal {
  width: 900px;
  max-width: 900px !important;
}

.user-details-page {
  width: 470px;
}

.payment-details-modal {
  width: 470px;
}

.payment-details-modal-details {
  width: auto;
}

.claim_account_modal {
  /*width: 480px;*/
  width: 100%;
  max-width: 480px;
}

.claim_account_modal_contact {
  width: 100%;
  max-width: 742px;
}

.payment-modal {
  width: 100%;
  max-width: 600px;
}

.delete-modal .modal-content {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.claim_account_modal .modal-content {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.claim_account_modal_contact .modal-content {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

@media (max-width: 767px) {
  .delete-modal {
    width: 100%;
  }

  .claim_account_modal {
    width: 100%;
  }

  .claim_account_modal_contact {
    width: 100%;
  }
}

.delete-button {
  background-image: url(../images/deletebutton.png);
  line-height: 19px;
  background-position: center;
}

.booking-btn1 {
  background-image: url(../images/bookinbtn1.svg);
  line-height: 19px;
}

.mini-button {
  background-image: url(../images/miniBtn.png);
  line-height: 4px;
  width: 115.671px;
  height: auto;
  flex-shrink: 0;
}

.delete-confirm-button {
  background-image: url(../images/deleteconfirmbutton.png);
  line-height: 16px;
  background-position: center;
}

.white-border-btn {
  background-image: url(../images/white-border-btn.png);
  line-height: 16px;
}

.map-tooltip {
  width: 250px;
  border-radius: 5px;
}

[title="Close"] {
  display: none !important;
}

.p_image_shape_map {
  width: 24px;
  height: 22px;
  min-width: 22px;
}

.p_image_shape_map img {
  width: 20px;
  height: 19px;
}

.map_name {
  font-size: 14px;
  color: #3e555b;
  font-weight: 500;
}

.map_title {
  font-size: 18px;
  color: #000000;
  font-weight: 600;
}

.map_des {
  font-size: 14px;
  color: #e19e19;
  font-weight: 500;
}

.p_image_map_icon {
  width: 36px;
  height: 32px;
  min-width: 32px;
  background-size: cover;
  background-image: url(../images/shape5.png);
}

.p_image_map_icon img {
  width: 18px;
  height: 18px;
}

.accordion-item:first-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion-item {
  margin-bottom: 15px;
  background-color: #fff;
  border: none !important;
  padding: 1px;
  background: linear-gradient(180deg, #0dbcec, #ffc056);
  box-shadow: 0px 0px 10px 0px rgba(172, 209, 217, 0.75);
}

.transaction_accodian_item {
  margin-bottom: 15px;
  background-color: #fff !important;
  border: none !important;
  padding: 0 !important;
  border-radius: 10px !important;
  box-shadow: 0px 0px 10px 0px rgba(172, 209, 217, 0.75);
}

.accordion-body {
  background-color: #fff;
}

.accordion-button:focus {
  border-color: transparent !important;
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  color: #000000;
  background-color: #ecfbff;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}

.accordion-button {
  font-size: 15px;
  font-size: 17px !important;
  font-weight: 500;
  padding: 20px 15px;
}

.accordion-body,
.accordion-body p {
  font-size: 17px;
  color: #5f5f5f;
}

.container.position-absolute {
  top: 0;
  left: 50%;
  transform: translate(-50%, 0px);
}

.map-select {
  padding: 14px 20px !important;
}

.terms {
  font-size: 20px;
  letter-spacing: 0.3px;
  color: #676767;
  margin-bottom: 35px;
}

.subpoint {
  font-weight: 600;
  color: #000;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 20px;
}

.subpoint li,
p {
  list-style-type: circle;
  font-weight: 500;
  margin-top: 5px;
  font-size: 17px;
}

.span_div {
  font-weight: 600;
  color: #3e555b;
  margin-bottom: 10px;
}

.span_div span {
  font-weight: 500;
  color: #5f5f5f;
}

.new_sub {
  font-weight: 600;
  color: #3e555b;
  margin-bottom: 10px;
  font-size: 17px;
  margin-left: 20px;
}

#scrollableDiv::-webkit-scrollbar {
  width: 0px;
}

.grey-text {
  color: #505050;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.page-Container-height {
  height: 320px;
  overflow-y: scroll;
}

.page-Container-height::-webkit-scrollbar {
  display: none;
}

/*..........profile page........*/

.review-Name {
  font-size: 12px;
  font-weight: 600;
}

.upComing_count {
  width: 30px;
  height: 30px;
  border: 2px solid #ffa500;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  right: 20px;
  /* top: 50%; */
  bottom: 8px;
  margin-right: 8px;
}

.pro_carousel {
  background-image: url(../images/shape-6.6e509d763bcd8e74f6be.png);
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 325px;
  cursor: pointer;
}

.pro_carousel_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.pro_carousel_desc {
  width: 63%;
  font-size: 12px;
  text-align: center;
}

.pro_carousel_title {
  font-size: 15px;
  font-weight: 600;
  color: #000;
  font-weight: 600;
  width: 52%;
  text-align: center;
}

.pro_carousel_price {
  font-size: 20px;
  font-weight: 700;
}

.pro_carousel_time {
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  margin-top: 2px;
  color: #000;
}

/*..........booking page...........*/

.card_green_arrow {
  position: absolute;
  top: 10px;
  right: 10px;
}

.massageBtn {
  background-image: url("../images/massage_btn.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  max-width: 280px;
  height: 40px;
  margin-top: 15px;
}

/*.......session booking.....*/

.session-select .ant-select-selector {
  width: 100% !important;
  font-size: 17px !important;
  height: 44px !important;
  padding: 4px 10px !important;
  font-weight: 400 !important;
  color: black !important;
}

.session-input {
  background: white;
  border-radius: 8px;
  padding: 10px;
}

.session-input input {
  border: none;
  margin-left: auto;
  outline: none;
}

.session-address {
  border: 1px dashed black;
  border-radius: 8px;
  padding: 10px;
}

.textElips {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;
}

.textElips1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;
}

.cardName {
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
}

.cardexpire {
  font-size: 11px;
  font-weight: 600;
}

/*....mui calander...........*/

.css-cwhad8-MuiDateCalendar-root {
  transform: scale(1.2);
  margin-left: 50px;
  margin-top: 40px;
  overflow: hidden;
}

.css-cwhad8-MuiDateCalendar-root {
  margin-left: 30px !important;
  /*margin-top: 15px !important;*/
  /*display: flex !important;*/
  /*width: 100% !important;*/
  /*justify-content: center !important;*/
}

.css-1rv8zyt-MuiStack-root {
  overflow: hidden !important;
}

.calander-bg {
  background-image: url(../images/calanderBg.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.css-1u23akw-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-image: linear-gradient(
    to left bottom,
    #dae1ae,
    #cddeaf,
    #c1dcb0,
    #b6d8b2,
    #abd5b4
  ) !important;
  color: black !important;
  border-radius: 5px;
  background-size: 100% 100%;
}

.event-popup {
  width: 90vw;
  max-width: 90vw;
}

.evant-card-bg {
  background: rgba(255, 165, 0, 0.1);
  cursor: pointer;
}

.eventtextarea {
  border: 1px dashed #000000;
  width: 100%;
  border-radius: 10px;
  resize: none;
  overflow: auto;
  resize: none;
  height: 60px;
}

.eventModal .modal-dialog {
  max-width: 865px !important;
}

.calander_contant {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100% !important;
}

.calender_height {
  height: 100% !important;
}

.walletpopText {
  color: black !important;
}

.walletpopTextRed {
  color: red !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1 !important;
}

.showArrow::-webkit-outer-spin-button,
.showArrow::-webkit-inner-spin-button {
  -webkit-appearance: button;
  margin-left: 10px;
  /*margin: 10px;*/
}

.ant-rate-star-second {
  color: rgb(0 0 0 / 25%);
}

/*..................tab.............*/

.eventTab {
  background: linear-gradient(
    180deg,
    rgba(92, 203, 234, 0.3) 0%,
    rgba(239, 206, 150, 0.3) 100%
  );
  display: flex;
  border-radius: 10px;
  padding: 10px;
}

.tab--Active {
  background: white;
  color: black;
}

.tab-button {
  font-size: 16px;
  font-weight: 700;
  padding: 10px 25px;
  cursor: pointer;
  border-radius: 5px;
}

.tab-button:hover {
  background: rgba(255, 255, 255, 0.31);
}

/*............service layouts..................*/

.left-arrow-Container {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
}

.right-arrow-Container {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.review-text {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.providerDitails .react-multi-carousel-track {
  margin-bottom: 0px !important;
}

.removeScroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.removeScroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.home-desc {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/*.......schedule............*/

.scheduleScroll {
  height: calc(100vh - 320px);
  overflow: scroll;
}

.scheduleScroll::-webkit-scrollbar {
  display: none;
}

.scheduleScroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.collabrate-modale {
  max-width: 906px !important;
}

.question-modale {
  max-width: 728px !important;
}

.collabrate-event-list {
  max-height: 460px;
  overflow-y: auto;
  scrollbar-color: #a7cdbf #ffffff !important;
  scrollbar-width: thin !important;
}

.collabrate-event-list::-webkit-scrollbar-track {
  background: #ffffff !important;
}

.collabrate-event-list::-webkit-scrollbar-thumb {
  background-color: #a7cdbf !important;
  border-radius: 6px !important;
  border: 3px solid #ffffff !important;
}

.collabrate-event-list::-webkit-scrollbar {
  width: 11px !important;
}

.colabrote_service_dropdown {
  padding: 8px 18px;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
}

.colabrote_service_dropdown:hover {
  background: aliceblue;
}

.newelips {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.save-page-btn {
  width: 80%;
}

.save-page-content {
  width: 100%;
  max-width: 50%;
}

.grptextarea {
  border-radius: 10px;
  border: 1px solid #d0dee2;
  padding: 9px;
  background: rgba(255, 255, 255, 0.5);
  margin-top: 25px;
  box-shadow: 0px 0px 10px 0px rgba(172, 209, 217, 0.75);
}

.grupicon {
  position: fixed !important;
  bottom: 30px;
  right: 30px;
  gap: 0px;
}
@media (max-width: 1375px) {
  .save-page-content {
    width: 100%;
    max-width: 55%;
  }
}

@media (max-width: 1280px) {
  .save-page-content {
    width: 100%;
    max-width: 60%;
  }
}

@media (max-width: 1190px) {
  .save-page-content {
    width: 100%;
    max-width: 70%;
  }
}

@media (max-width: 1070px) {
  .save-page-content {
    width: 100%;
    max-width: 75%;
  }
}

@media (max-width: 1020px) {
  .save-page-content {
    width: 100%;
    max-width: 80%;
  }
}

@media (max-width: 950px) {
  .save-page-content {
    width: 100%;
    max-width: 90%;
  }
}

@media (max-width: 898px) {
  .save-page-content {
    width: 100%;
    max-width: 38%;
  }
}

@media (max-width: 768px) {
  .save-page-content {
    width: 100%;
    max-width: 50%;
  }
}

.claimAccount_logo_bg {
  background: #effcff;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.ContactTextArea {
  border-radius: 10px;
  border: 1.5px dashed rgba(0, 0, 0, 0.3);
  padding: 12px;
  resize: none;
  width: 100%;
  height: 165px;
}

.claim-popup-link {
  color: #e19200;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  text-decoration-line: underline;
  cursor: pointer;
}

.claim-popup-link:hover {
  color: #a96f03;
}

/*...book date .....*/

.sd-container {
  position: relative;
  /*float: left;*/
}

.sd {
  padding: 5px 10px;
  height: 30px;
  width: 150px;
}

.open-button {
  position: absolute;
  top: 0px;
  font-size: 14px;
  right: 3px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  background: #fff;
  pointer-events: none;
}

.open-button button {
  border: none;
  background: transparent;
}

.react-joyride__tooltip div div {
  padding: 0px !important;
}

.react-joyride__tooltip button[data-test-id="button-skip"],
.react-joyride__tooltip button[data-test-id="button-back"] {
  /* Add your styles here */
  /*background-color: #yourColor;*/
  display: none;
  /* Other styles */
}

.react-joyride__tooltip button[data-test-id="button-primary"] {
  /* Add your styles here */
  /*background-color: #yourColor;*/
  background-color: transparent !important;
  color: #ffa500 !important;
  font-size: 14px !important;
  /*margin-top: 10px !important;*/
  padding: 0 !important;
  /* Other styles */
}

.notification-main-div:hover + .delete_notification {
  /*padding: 10px;*/
  display: block;
  /*width: 400px;*/
  /*max-width: 400px;*/
  /*border-radius: 15px;*/
  /*box-shadow: -8px 0px 29px 0px rgba(0, 0, 0, 0.03);*/
}

.notificationScroll {
  /*max-height: 460px;*/
  overflow-y: scroll;
  scrollbar-color: #a7cdbf #ffffff !important;
  scrollbar-width: thin !important;
}

.notificationScroll::-webkit-scrollbar-track {
  background: #ffffff !important;
}

.notificationScroll::-webkit-scrollbar-thumb {
  background-color: #ffa500 !important;
  border-radius: 6px !important;
  border: 3px solid #ffffff !important;
}

.notificationScroll::-webkit-scrollbar {
  width: 11px !important;
}

.twoLineElips {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* ===== WALLET ======= */

.walletTabsdiv {
  padding: 10px;
  display: flex;

 
  border-radius: 10px;
  background: linear-gradient(
    180deg,
    rgba(92, 203, 234, 0.3) 0%,
    rgba(239, 206, 150, 0.3) 100%
  );
}

.wallet_tabs {
  text-align: center;
  width: 100%;
  max-width: 157px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 5px;
  cursor: pointer;
}

.wallet_tabs p {
  color: #585858;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.wallet_tabs:hover {
  background: rgba(255, 255, 255, 0.42);
}

.wallet_tabs_select p {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #000 !important;
}

.wallet_tabs_select {
  background: white;
}

.wallet_tabs_select:hover {
  background: white;
}

.wallet_green {
  font-size: 25px !important;
  color: #008000 !important;
  font-weight: 800 !important;
}

@media (max-width: 768px) {
  .wallet_green {
    font-size: 15px !important;
    white-space: nowrap;
  }

  .wallet_red {
    font-size: 15px !important;
    white-space: nowrap;
  }
}

.wallet_red {
  font-size: 25px !important;
  color: #f93939 !important;
  font-weight: 800 !important;
}

.sub_heading {
  font-size: 22px;
  font-weight: 600;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

@media (max-width: 767px) {
  .sub_heading {
    font-size: 20px;
    padding-bottom: 8px;
  }

  .mb-30 {
    margin-bottom: 15px;
  }
}

.support_modal .modal-dialog {
  max-width: 723px !important;
}

.white-space {
  white-space: nowrap;
}

.event-partisipents-panding-status {
  /*border: 1px solid #FFA500;*/
  /*padding: 7px;*/
  /*border-radius: 6px;*/
  /*background: #FFF0D6;*/
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #ffa500 !important;
}

.event-partisipents-cancel-status {
  /*border: 1px solid #FFA500;*/
  /*padding: 7px;*/
  /*border-radius: 6px;*/
  /*background: #FFF0D6;*/
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #fe5a5a !important;
}

.discount .ant-select-selection-search-input {
  margin-top: 5px;
}

.font-size .ant-select-selection-item {
  font-size: 15px;
}
.font-size .ant-select-selection-search {
  display: flex;
  justify-content: center;
  align-items: center;
}
.discount-congo {
  max-width: 400px !important;
}

.location-hover {
  cursor: pointer;
}

.location-hover:hover p {
  text-decoration: underline;
  color: #0000ee;
  cursor: pointer;
  transition-duration: 0.2s;
}

.ticketBox {
  background: linear-gradient(270deg, #dae1ae 0%, #abd5b4 99.79%);
  padding: 12px 21px !important;
  border-radius: 5px;
  cursor: pointer;
  text-transform: capitalize !important;
}

.ticketBox p {
  color: #363853;
  font-weight: 600;
}
.service-in-discount:hover {
  color: #06069e !important;
  cursor: pointer;
}
.blue-check-event {
  position: absolute;
  right: 5px;
  top: 1px;
  clip-path: none;
  height: 24px;
  width: 26px;
  z-index: 2;
}

.map-main-box {
  background: white;
  border-radius: 10px;
  border: 1px solid #d1d1d1;
}

.btn:focus {
  box-shadow: none !important;
}

.recDatePiker .anticon-calendar {
  display: none;
}

.ant-picker-dropdown {
  z-index: 100000;
}

.ant-picker-clear {
  display: none;
}

.modal-dialog {
  margin: auto !important;
  padding: 10px !important;
}

.ant-select-auto-complete {
  width: 100%;
}

.reviewModal .modal-dialog {
  max-width: 600px;
}
.MuiTextField-root.w-100 .MuiInputBase-root.Mui-readOnly {
  padding: 0 !important;
}

.ant-picker-input {
  background-color: #fff;
}

.btn {
  transition: all 0.5s;
}

.collepsActive {
  transform: rotate(180deg);
}
.instaTemp {
  max-width: 900px;
}

.instaTemp .Congratulations_msg {
  max-width: 100%;
}

.chartTextVer {
  dominant-baseline: middle;
}

.chartTextHor {
  text-anchor: middle;
}

.text-croissant {
  font-family: "Croissant One", serif;
}

.text-oleo {
  font-family: "Oleo Script", system-ui;
}

.text-lexend {
  font-family: "Lexend", sans-serif;
}

.text-sansita {
  font-family: Sansita;
}

.text-inria {
  font-family: "Inria Serif", serif;
}

.text-rubik {
  font-family: "Rubik", sans-serif;
}

.text-source {
  font-family: "Source Serif 4", serif;
}
.text-yrsa {
  font-family: "Yrsa", serif;
}
.text-Cambria {
  font-family: Cambria;
}

.text-poppins {
  font-family: "Poppins", sans-serif;
}

.text-anek {
  font-family: "Anek Malayalam", sans-serif;
}

.text-inter {
  font-family: "Inter", sans-serif;
}
.text-markoone {
  font-family: "Marko One", serif;
}

.text-racing {
  font-family: "Racing Sans One", sans-serif;
}

.text-caudex {
  font-family: "Caudex", serif;
}

.text-amaranth {
  font-family: "Amaranth", sans-serif;
}

.text-roboto {
  font-family: "Roboto", sans-serif;
}

.text-bigshot {
  font-family: "Bigshot One", serif;
}

.text-istok {
  font-family: "Istok Web", sans-serif;
}

.text-candal {
  font-family: "Candal", sans-serif;
}

.text-elips {
  word-break: break-word;
  clip-path: none;
  height: auto;
  display: -webkit-box;
  padding: 5px;
}

.ant-drawer-header {
  border-bottom: none !important;
}

.ant-drawer-body {
  padding: 0px !important;
}

.ant-drawer-header-title {
  display: flex;
  justify-content: end;
}

.ant-drawer-content-wrapper {
  max-height: 100vh;
  overflow: auto;
}

.ant-drawer-header-title .ant-drawer-close {
  margin: 0;
}

#scrollableDiv ::-webkit-scrollbar {
  width: 0px !important;
}

.MuiInputBase-root {
  padding: 0px !important;
}

.MuiLinearProgress-colorPrimary {
  background-color: #ffc056 !important;
}

.MuiLinearProgress-barColorPrimary {
  background-color: #0dbcec !important;
}

.modal-383w{
  width: 383px;
}

.anticon-dot-chart{
  display: none !important;
}

.home-listing .ant-skeleton{
  width: 100%;
}

.w-600{
  max-width : 600px !important
}

.excel-menu-container {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin:  0 0px 0 40px;
}

/* Three dots button */
.excel-dots {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
  width: 20px;
  padding: 5px;
}

.excel-dots span {
  width: 4px;
  height: 4px;
  background-color: black;
  border-radius: 50%;
  display: block;
}

/* Dropdown menu (hidden by default) */
.excel-menu {
  z-index: 1;
  position: absolute;
  right: 0;
  top: 20px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  width: 80px;
  display: none;
}

/* Menu items */
.excel-menu div {
  display: block;
  padding: 8px;
  font-size: 14px;
  text-decoration: none;
  color: black;
  text-align: left;
}

.excel-menu div:hover {
  background: #ffffff;
  width: 186px;
}

/* Show menu on hover */
.excel-menu-container:hover .excel-menu {
  width: 190px;
  display: block;
}