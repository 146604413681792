/*..........commone..........*/


/*.....forn.........*/

.t-30-bold {
    font-size: 30px !important;
    font-style: normal;
    font-weight: 700 !important;
    color: #000 !important;
    word-break: break-word;
}

.t-25-bold {
    font-size: 25px !important;
    color: black !important;
    font-weight: 600 !important;
    word-break: break-word;
}

.t-24-bold {
    font-size: 24px !important;
    font-weight: 600 !important;
    word-break: break-word;
}

.t-22-bold {
    /*color: #000 !important;*/
    font-size: 22px !important;
    font-weight: 600 !important;
    word-break: break-word;
}

.t-20-bold {
    /*color: #000 !important;*/
    font-size: 20px !important;
    word-break: break-word;
    font-weight: 600 !important;
}

.t-20-500 {
    /*color: #000 !important;*/
    font-size: 20px !important;
    word-break: break-word;
    font-weight: 500 !important;
}

.t-19-bold {
    font-size: 19px !important;
    font-weight: 600 !important;
    word-break: break-word;
}

.t-19-500 {
    font-size: 19px !important;
    font-weight: 500 !important;
    word-break: break-word;
}

.t-18-bold {
    font-size: 18px !important;
    font-weight: 600 !important;
    word-break: break-word;
}

.t-18-500 {
    font-size: 18px !important;
    font-weight: 500 !important;
    word-break: break-word;
}

.t-17-bold {
    font-size: 17px !important;
    font-weight: 600 !important;
    word-break: break-word;
}

.t-17-500 {
    font-size: 17px !important;
    font-weight: 500 !important;
    word-break: break-word;
}

.t-16-bold {
    color: black;
    font-size: 16px !important;
    font-weight: 600 !important;
    word-break: break-word;
}

.t-15-bold {
    font-size: 15px !important;
    font-weight: 700 !important;
    word-break: break-word;
}

.t-15-500 {
    font-size: 15px !important;
    font-weight: 500 !important;
    word-break: break-word;
}

.t-16-bold {
    font-size: 16px !important;
    word-break: break-word;
    font-weight: 600 !important;
}

.t-16-500 {
    font-size: 16px !important;
    font-weight: 500 !important;
    word-break: break-word;
}

.t-14-bold {
    font-size: 14px !important;
    word-break: break-word;
    font-weight: 600 !important;
}

.t-14-500 {
    font-size: 14px !important;
    word-break: break-word;
    font-weight: 500 !important;
}

.t-13-bold {
    font-size: 13px !important;
    font-weight: 600 !important;
    word-break: break-word;
}

.t-13-800 {
    font-size: 13px !important;
    font-weight: 800 !important;
    word-break: break-word;
}

.t-13-500 {
    font-size: 13px !important;
    font-weight: 500 !important;
    word-break: break-word;
}

.t-12-bold {
    font-size: 12px !important;
    font-weight: 600 !important;
    word-break: break-word;
}

.t-12-500 {
    font-size: 12px !important;
    font-weight: 500 !important;
    word-break: break-word;
}

.t-11-bold {
    font-size: 11px !important;
    font-weight: 600 !important;
    word-break: break-word;
}

.t-11-500 {
    font-size: 11px !important;
    font-weight: 500 !important;
    word-break: break-word;
}

.t-10-bold {
    font-size: 12px !important;
    font-weight: 600 !important;
    word-break: break-word;
}

.t-10-500 {
    font-size: 12px !important;
    word-break: break-word;
    font-weight: 500 !important;
}

.yelloe-t-bold {
    color: #E19E19 !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    word-break: break-word;
}


.text-black {
    color: black !important;
}

.text-gray {
    color: #636363 !important;
}

.text-dark-gray {
    color: #585858 !important;
}

.text-light-gray {
    color: #505050 !important;
}

h3, h1, h2, h4, h5, h6 {
    word-break: break-word;
}

.c-pointer {
    cursor: pointer
}

/*............box_shadow.............*/

.box_shadow {
    box-shadow: 0px 0px 10px 0px rgba(172, 209, 217, 0.73);
    transition-duration: 0.5s;
}

.box_shadow_light {
    box-shadow: -8px 0px 29px 0px rgba(0, 0, 0, 0.03);
    transition-duration: 0.5s;
}

.box_shadow:hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
}


/*............border........*/

.yellow_border {
    border: 2px solid #FFA500;
    cursor: pointer;
}

.grey_border {
    border: 1px solid #D0DEE2;
}

.black_border {
    border: 1.5px solid rgba(0, 0, 0, 0.20);
    border-radius: 10px;
}


.showArrow::-webkit-outer-spin-button,
.showArrow::-webkit-inner-spin-button {
    -webkit-appearance: button;
    margin-left: 10px;
    /*margin: 10px;*/
}


/*...............calander..............*/

.custom-calendar {
    box-shadow: none !important;
    background-image: url(../images/calanderBg.png) !important;
}

.custom-today-day {
    /*color: black !important;*/
    border: 1px solid #5CCBEA !important;
    border-radius: 10px !important;
}

.custom-today-day::after {
    /*visibility: hidden; !* hide small border under the text *!*/
}

.custom-selected-day {
    border-radius: 9px !important;
    background: linear-gradient(270deg, #DAE1AE 0%, #ABD5B4 99.79%) !important;
    color: black !important;
}

.purpleDay:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected):after {
    content: "\A";
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #11B5D9;
    display: inline-block;
    margin-left: -9px;
    margin-top: 6px;
    margin-bottom: -20px;
}

.yellowDay:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
    background: #FFA50080 !important;
    color: #000 !important;
    border-radius: 10px !important;
}

.css-bkzwk2-MuiStack-root {
    padding-top: 0 !important;
}


/*.................carousel...................*/

.react-multi-carousel-dot button {
    background: #FFA50030 !important;
    border: none !important;
}

.custom-dot-list-style {
    margin-top: 50px !important;
}

.react-multi-carousel-dot--active button {
    background: Orange !important;
}


.carousel-margin, .react-multi-carousel-track {
    margin-bottom: 30px !important;
}


@media only screen and (max-width: 425px) {
    .t-17-bold {
        font-size: 15px !important;
    }

    .t-19-500 {
        font-size: 16px !important;
    }

    .t-25-bold {
        font-size: 20px !important;
    }

    .t-30-bold {
        font-size: 20px !important;
    }

    .t-20-bold {
        font-size: 17px !important;
        font-weight: 600 !important;
        width: 295px;
    }

    .small-20-bold {
        font-size: 15px !important;
        font-weight: 600 !important;
        width: 295px;
    }

    .t-24-bold {
        font-size: 20px !important;
        font-weight: 600 !important;
        word-break: break-word;
    }

    .t-16-bold {
        color: black;
        font-size: 14px !important;
        font-weight: 600 !important;
    }

    .t-15-bold {
        font-size: 14px !important;
        font-weight: 700 !important;
    }

    .t-15-500 {
        font-size: 14px !important;
        font-weight: 500 !important;
    }


    .t-22-bold {
        /*color: #000 !important;*/
        font-size: 19px !important;
        font-weight: 600 !important;
    }

    .t-18-bold {
        font-size: 16px !important;
    }

    .t-18-500 {
        font-size: 14px !important;
    }
}


.antdAutoComplate .ant-select-selection-placeholder {
    margin-top: 5px !important;
}

.antdAutoComplate .ant-select-selector {
    height: 46px !important;
}

.antdAutoComplate .ant-select-selection-search-input {
    margin-top: 5px !important;
}


.share .react-multi-carousel-item {
    text-align: center;
}

.share .react-multi-carousel-track {
    margin-bottom: 0 !important;
}

/*.........btn-loader......*/

.inner-gred .country-list ul li {
    margin-bottom: 0 !important;
}

.Congratulations_msg .custom-dot-list-style {
    margin-top: 0 !important;
}

.Congratulations_msg .react-multi-carousel-track {
    margin-bottom: 0 !important;
}

.react-tel-input .country-list .country {
    margin-bottom: 0 !important;
}

.Calendar__day.-ltr.-selectedStart {
    border-radius: 10px !important;
    color: black !important;
}

.Calendar__day.-ltr.-selectedEnd {
    border-radius: 10px !important;
    color: black !important;
}

.noti-filter {
    padding: 5px 2px 5px 23px;
    padding-bottom: 7px;
    padding-top: 7px;
    /**/
}

.noti-filter:hover {
    background: rgba(150, 237, 255, 0.24);
    cursor: pointer;
    /*padding: 5px 2px 5px 23px;*/
}

.noti-mainDiv {
    padding: 10px;
    width: 165px;
}

.hideScroll::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScroll {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}


/*body {*/
/*    !*max-height: 460px;*!*/
/*    overflow-y: scroll;*/
/*    scrollbar-color: #a7cdbf #ffffff !important;*/
/*    scrollbar-width: thin !important;*/
/*}*/

/*body::-webkit-scrollbar-track {*/
/*    background: #ffffff !important;*/
/*}*/

/*body::-webkit-scrollbar-thumb {*/
/*    background: linear-gradient(0deg, rgb(255, 255, 255) 0%, #FFA500 100%);;*/
/*    !*background-color: #FFA500 !important;*!*/
/*    border-radius: 6px !important;*/
/*    border: 3px solid #ffffff !important;*/
/*}*/

/*body::-webkit-scrollbar {*/
/*    width: 13px !important;*/
/*}*/

.seven-line {
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-line;
}

.three-line {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-line;
}

.two-line {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-line;
}

.one-line {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-line;
}

.ant-tooltip {
    z-index: 100000 !important;
}

.notification-main-div:hover .delete_notification {
    display: block;
    transition-duration: 0.4s;
}

.delete_notification {
    display: none;
    transition-duration: 0.4s;
}

.timePicker-Event .MuiFormControl-root {
    width: 100% !important;
}

.event-flow .infinite-scroll-component__outerdiv {
    height: 100% !important;
}

.eventinfi {
    height: 100% !important;
    overflow: visible !important;
}

.ant-select-dropdown {
    z-index: 10000 !important;
}

@media (max-width: 767px) {
    .custom-popover {
        max-width: 356px;
    }

    .wallet_tabs {
        max-width: 120px !important;
    }
}

@media (max-width: 375px) {
    .ad-my-event {
        width: 60% !important;
        align-items: start !important;
    }

    .wallet_tabs {
        max-width: 110px !important;
    }
}

@media (max-width: 500px) {
    .ad-my-event {
        width: 60% !important;
        align-items: start !important;
    }

}

@media (max-width: 400px) {
    .ad-my-event {
        width: 70% !important;
        align-items: start !important;
    }

}

@media (max-width: 1200px) {
    .ref-desc-width {
        width: 86% !important;
    }

}

@media (max-width: 950px) {
    .g-code-btn {
        bottom: -55px !important;
    }

}

.step_bg {
    background: linear-gradient(270deg, #DAE1AE 0%, #ABD5B4 99.79%);
    display: flex;
    padding: 7px 15px;
    border-radius: 50%;
}

.ref-desc-width {
    width: 62%;
}

.ref-desc {
    word-break: break-word;
}

.ref-code-box {
    border: 1px solid #D0DEE2;
    background: #FFFFFFB2;
    box-shadow: 0px 0px 10px 0px #ACD1D9BA;
    border-radius: 10px;
    padding: 20px;

}

.slide-modle {
    background: transparent !important;
    border: none !important;

}

.slide-modle .modal-content {
    padding: 20px;
    border-radius: 20px;
    background: white !important;
    border: none !important;
    box-shadow: none !important;
}
.slide-modle .modal-dialog {
   max-width: 680px !important;
}